import React from 'react'
import Gallery from "../Components/Gallery";
import GalleryAlt from '../Components/GalleryAlt';
import Main from "../Components/Main";
import Footer from "../Components/Footer";
import "../App.css"

export default function Home() {
  return (
    <div>
      <Main/>
      {/* <MainContext/> */}
      {/* <Gallery/>
      <GalleryAlt/> */}
    </div>
  )
}