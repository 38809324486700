import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Row, Col } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

export const Contact = () => {
  const form = useRef();
  const refCaptcha = useRef(); // <- add the useRef hook


  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    budget: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleRecaptchaChange = (value) => {
    console.log("reCAPTCHA value:", value);
  }



  const handleSubmit = (e) => {
    e.preventDefault();

    const btn = document.getElementById('button');


    const token = refCaptcha.current.getValue(); // <- `getValue()` from the instantiated refCaptcha

    const params = {
      ...formData,
      'g-recaptcha-response': token,
    }; // <- Create this object spreading your state and adding the retrieved token as a value of 'g-recaptcha-response'
    

    // Send email using EmailJS
    const serviceID = 'service_h52pwh8'; // Replace with your EmailJS service ID
    const templateID = 'template_oh2288q'; // Replace with your EmailJS template ID
    const publickey = 'bYLkWtK5PDb3bc8dO'

    emailjs.send(serviceID, templateID, params, publickey)
      .then((response) => {
        console.log('Email sent:', response.status, response.text);
        console.log(params)
        alert('Email sent!');
      }, (error) => {
        console.error('Error sending email:', error);
        console.log(params)
        alert('Error sending email: ' + JSON.stringify(error));
      });
  };

  return (
    <>
      <Row className="padded-top-bot centered carouselposition">
        <Col md={6} className='maxheight centeredcol'>
          <br></br>
          <h1 className='centered'>Let's Get To Work!</h1>
          <br></br>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">What's your name?</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Where should we email?</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">What can we do for you?</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="budget">[Optional] What is your budget range?</label>
              <input
                type="text"
                id="budget"
                name="budget"
                value={formData.budget}
                onChange={handleChange}
              />
            </div>
            <ReCAPTCHA ref={refCaptcha}
              sitekey="6LdQiEwoAAAAAJoP0Yg9F4ExpGJ3DYgPmJGc84CY" // Replace with your reCAPTCHA Site Key
              onChange={handleRecaptchaChange}
              className="centered"
            />
            <br></br>
            <button className='buttonContact' type="submit">Send Message</button>
          </form>
          <br></br>
        </Col>
        <Col md={6} className='maxheight centeredcol'>
          <img src="images/leatherwork.jpg" alt="leatherwork" />
        </Col>
      </Row>
    </>
  );
};

export default Contact


// import React from 'react'
// import "../App.css"
// import { Row, Col, Carousel } from "react-bootstrap";

// const Contact = () => {
//   const [formStatus, setFormStatus] = React.useState('Send')
//   const onSubmit = (e) => {
//     e.preventDefault()
//     setFormStatus('Submitting...')
//     const { name, email, message } = e.target.elements
//     let conFom = {
//       name: name.value,
//       email: email.value,
//       message: message.value,
//     }
//     console.log(conFom)
//   }
//   return (
//     <Row>
//       <Col md={6} className="maxheight padded-top-bot ">
//         <div className="container mt-5 commissionForm">
//           <h2 className="centered mb-3">Let's Get It Started: </h2>
//           <form onSubmit={onSubmit}>
//             <div className="mb-3">
//               <label className="form-label formText" htmlFor="Name">
//                 Name
//               </label>
//               <input className="form-control" type="text" id="name" required />
//             </div>
//             <div className="mb-3">
//               <label className="form-label formText" htmlFor="Email">
//                 Email
//               </label>
//               <input className="form-control" type="email" id="email" required />
//             </div>
//             <div className="mb-3">
//               <label className="form-label formText" htmlFor="Message">
//                 Request
//               </label>
//               <textarea className="form-control form-control-lg" id="message" required />
//             </div>
//             <div className='loginButtonSection'>
//               <button className="btn btn-danger" type="submit">
//                 {formStatus}
//               </button>
//             </div>
//           </form>
//         </div>
//       </Col>
//       <Col md={5} className="padded-top-bot carouselposition centeredcol">
//         <Carousel className="carouselsize">
//                     <Carousel.Item>
//                         <img
//                             className="d-block w-100 imageSize"
//                             src="images/DSC06141.JPG"
//                             alt=""
//                         />
//                         <Carousel.Caption>
//                             <h3>Made to Measure Belts</h3>
//                             <p></p>
//                         </Carousel.Caption>
//                     </Carousel.Item>
//                     <Carousel.Item>
//                         <img
//                             className="d-block w-100 imageSize"
//                             src="images/bag.jpg"
//                             alt=""
//                         />

//                         <Carousel.Caption>
//                             <h3>Free Personalization</h3>
//                             <p></p>
//                         </Carousel.Caption>
//                     </Carousel.Item>
//                     <Carousel.Item>
//                         <img
//                             className="d-block w-100 imageSize"
//                             src="images/DSC06680.JPG"
//                             alt=""
//                         />
//                         <Carousel.Caption>
//                             <h3>Custom Projects</h3>
//                             <p></p>
//                         </Carousel.Caption>
//                     </Carousel.Item>
//                 </Carousel>

//       </Col>
//     </Row>
//   )
// }
// export default Contact



