import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Container, Row, Offcanvas } from "react-bootstrap";
import React, { useState, useEffect } from 'react';

const Navigation = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const disableContextMenu = (event) => {
            event.preventDefault();
        }

        document.addEventListener('contextmenu', disableContextMenu);

        return () => {
            document.removeEventListener('contextmenu', disableContextMenu);
        }
    }, []);

    return (
        <div>
            {isMobile ? (
                <MobileView />
            ) : (
                <DesktopView />
            )}
        </div>


    );
};

export default Navigation;


function MobileView() {
    return (
        <div>
            <Row className="centered">
                <Navbar className="centered" expand="lg" variant="light">
                    <Navbar.Brand className="brandfont">
                        <a href="/" className="centered logoposition">
                            <img
                                className="d-block w-50 imageSize "
                                src="images/brand_logo.png"
                                alt=""
                            /></a>
                    </Navbar.Brand>
                </Navbar>
            </Row>
            <Row className="">
                <h5 className="subbrandfont "> </h5>
            </Row>
            <Row className="centered carouselposition">
                {['md'].map((expand) => (
                    <Navbar className="centered navfont" key={expand} expand={expand}>
                        <Container className="centeredcol" fluid>
                            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                            <Navbar.Offcanvas
                                id={`offcanvasNavbar-expand-${expand}`}
                                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                placement="end"
                            >
                                <Offcanvas.Header closeButton>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <Nav className="justify-content-center flex-grow-1 pe-3 ">
                                        <Nav.Link href="/">Home</Nav.Link>
                                        <Nav.Link href="/new_arrivals">New Arrivals</Nav.Link>
                                        <Nav.Link href="/catalog">Catalog</Nav.Link>
                                        <Nav.Link href="/commissions">Commissions</Nav.Link>
                                        <Nav.Link href="/about_me">About</Nav.Link>
                                        {/* <Nav.Link disabled href="/catalog" style={{ color: "gray" }}>Catalog</Nav.Link> */}
                                    </Nav>
                                </Offcanvas.Body>
                            </Navbar.Offcanvas>
                        </Container>
                    </Navbar>
                ))}
            </Row>
        </div >
    )
}

function DesktopView() {
    return (
        <div>
            <Row className="centered">
                <Navbar className="centered" expand="lg" variant="light">
                    <Navbar.Brand className="brandfont">
                        <a href="/" className="centered logoposition">
                            <img
                                className="d-block w-50 imageSize "
                                src="images/brand_logo.png"
                                alt=""
                            /></a>
                    </Navbar.Brand>
                </Navbar>
            </Row>
            <Row className="">
                <h5 className="subbrandfont "> </h5>
            </Row>
            <Row className="centered carouselposition">
                <Navbar className="centered navfont">
                    <Nav className="justify-content-center flex-grow-1 pe-3 ">
                        <Nav.Link href="/">Home | </Nav.Link>
                        <Nav.Link href="/new_arrivals">New Arrivals | </Nav.Link>
                        <Nav.Link href="/catalog">Catalog | </Nav.Link>
                        <Nav.Link href="/commissions">Commissions | </Nav.Link>
                        <Nav.Link href="/about_me">About</Nav.Link>
                        {/* <Nav.Link disabled href="/catalog" style={{ color: "gray" }}>Catalog</Nav.Link> */}
                    </Nav>
                </Navbar>
            </Row>
        </div >
    )
}